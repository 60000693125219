
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
    name: 'RegistrationAttachment',
    components: {},
})
export default class RegistrationAttachment extends Vue {
    @Prop({ required: false, default: null }) public value!: any;
}
