
import { Vue, Component, Prop } from 'vue-property-decorator';
import CountryRepository from '@/repositories/CountryRepository';

@Component({
    name: 'CompanyInformation',
    components: {},
})
export default class CompanyInformation extends Vue {
    @Prop({ required: true }) public value!: any;

    private get countries() {
        return [{'id': 23,'name': 'Slovenija', 'code': 'SI'},{'id': 1,'name': 'Avstrija', 'code': 'AT'}];
    }

    private get getCurrentCountryCode() {
        return this.countries.find(o => o.id === this.value.country)?.code;
    }

    private get flagUrl() {
        return `url(https://flagsapi.com/${this.getCurrentCountryCode}/flat/32.png)` ?? '';
    }
}
