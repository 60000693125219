import { ApiController } from '@/services/ApiController';
import { ApiRoutes } from '@/enums/api/ApiRoutes';
import { AxiosResponse } from 'axios';

export default class RegisterAPI {
    public static async register(payload: any) {
        let register;

        const formattedPayload = JSON.stringify(payload);
        const data = {
            data: {
                relationships: {},
                attributes: {
                    json: formattedPayload,
                },
                type: 'register_forms',
            },
        };

        try {
            register = (await ApiController.post(ApiRoutes.register.register, data)) as AxiosResponse;
        } catch (e) {
            return Promise.reject(e);
        }

        return Promise.resolve(register);
    }
}
