var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"register-form"},[_c('RoltekHeaderLogo'),_c('h2',{staticClass:"register-form__form-title register-form__title"},[_vm._v(" "+_vm._s(_vm.$t( 'For the purposes of creating a user-account in the ROLTEKapp for you company, we kindly ask you to fill out the following fields.' ))+" ")]),_c('div',{staticClass:"register-form__outer-wrap"},[_c('div',{staticClass:"register-form__segment"},[_c('span',{staticClass:"register-form__title"},[_vm._v(" "+_vm._s(_vm.$t('Company information'))+" ")]),_c('div',{staticClass:"register-form__inner-wrap"},[_c('CompanyInformation',{model:{value:(_vm.formData.companyInformation),callback:function ($$v) {_vm.$set(_vm.formData, "companyInformation", $$v)},expression:"formData.companyInformation"}})],1)]),_c('div',{staticClass:"register-form__segment"},[_c('span',{staticClass:"register-form__title tax-title"},[_vm._v(" "+_vm._s(_vm.$t('Tax:'))+" ")]),_c('span',{staticClass:"register-form__description"},[_vm._v(" "+_vm._s(_vm.taxDescription)+" ")]),_c('div',{staticClass:"register-form__inner-wrap"},[_c('AInputNumber',{staticClass:"register-form__input",attrs:{"min":0,"max":100,"formatter":(value) => `${value}%`,"parser":(value) => value.replace('%', ''),"size":"large"},model:{value:(_vm.formData.tax),callback:function ($$v) {_vm.$set(_vm.formData, "tax", $$v)},expression:"formData.tax"}})],1)]),_c('div',{staticClass:"register-form__segment"},[_c('span',{staticClass:"register-form__title tax-title"},[_vm._v(" "+_vm._s(_vm.$t('Type of payment'))+": ")]),_c('span',{staticClass:"register-form__description"},[_vm._v(" "+_vm._s(_vm.paymentDescription)+" ")]),_c('AInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'payment',
                    {
                        rules: [
                            { required: true, message: _vm.$t('Please input payment information!'), whitespace: true },
                        ],
                    },
                ]),expression:"[\n                    'payment',\n                    {\n                        rules: [\n                            { required: true, message: $t('Please input payment information!'), whitespace: true },\n                        ],\n                    },\n                ]"}],model:{value:(_vm.formData.payment),callback:function ($$v) {_vm.$set(_vm.formData, "payment", $$v)},expression:"formData.payment"}})],1),_c('div',{staticClass:"register-form__segment"},[_c('span',{staticClass:"register-form__title tax-title"},[_vm._v(" "+_vm._s(_vm.$t('Users'))+": ")]),_c('span',{staticClass:"register-form__description"},[_vm._v(" "+_vm._s(_vm.$t('Information about the users'))+": ")]),_c('UsersTable',{attrs:{"country":_vm.formData.companyInformation.country},model:{value:(_vm.formData.users),callback:function ($$v) {_vm.$set(_vm.formData, "users", $$v)},expression:"formData.users"}})],1),_c('div',{staticClass:"register-form__segment"},[_c('span',{staticClass:"register-form__title tax-title"},[_vm._v(" "+_vm._s(_vm.$t('Attachments'))+": ")]),_c('RegistrationAttachment')],1)]),_c('AButton',{staticClass:"register-form__submit-button",attrs:{"type":"primary","size":"large"},on:{"click":_vm.register}},[_vm._v(" "+_vm._s(_vm.$t('Register'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }