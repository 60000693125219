
import { Component } from 'vue-property-decorator';
import { UserRepository } from '@/repositories/UserRepository';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { NavigationGuardNext, Route } from 'vue-router/types/router';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import CompanyInformation from '@/components/global/register/CompanyInformation.vue';
import RegistrationAttachment from '@/components/global/register/RegistrationAttachment.vue';
import UsersTable from '@/components/global/register/UsersTable.vue';
import RoltekHeaderLogo from '@/icons/RoltekHeaderLogo.vue';
import RegisterAPI from '@/api/RegisterAPI';
import CountryRepository from '@/repositories/CountryRepository';
import Country from '@/models/Country';

@Component({
    name: 'RegisterForm',
    components: { CompanyInformation, RoltekHeaderLogo, RegistrationAttachment, UsersTable },
})
export default class RegisterForm extends mixins<UserRightsMixin>(UserRightsMixin) {
    private loadingOverlay = new LoadingOverlayHelper(this, {});

    private formData: any = {
        companyInformation: {
            title: '',
            taxNumber: '',
            taxablePerson: null,
            streetNumber: '',
            postCode: '',
            poste: '',
            country: 23,
        },
        tax: 0,
        payment: '',
        users: [
            {
                key: 0,
                name: '',
                surname: '',
                phone: '',
                email: '',
                salesRepresentative: true,
                contactPerson: true,
                admin: true,
                seesPrice: true,
            },
        ],
    };

    private get taxDescription() {
        return this.$t(
            'To avoid entering taxes individually when creating new offer each-time, fill in what value of tax (VAT) you charge on the products you offer to your customers (the app will automatically add the VAT value).'
        );
    }

    private get paymentDescription() {
        return this.$t(
            'Should you offer your customers a discount on 100% advance-payment, please let us know so that we can create a reference point for you, which will later be displayed on the offer. Otherwise, the default payment will be »Upon agreement«.'
        );
    }

    private async register() {
        let response;

        try {
            this.loadingOverlay.start();
            response = await RegisterAPI.register(this.formData);
            this.$notification.success({
                message: this.$t('Registration successful'),
                description: this.$t('We will review the data, enter it and send you the login information'),
            });
        } catch (e) {
            this.$notification.error({
                message: this.$t('Error happened'),
                description: e as string,
            });
        } finally {
            this.loadingOverlay.stop();
        }
    }

    private mounted() {
        this.$i18n.locale = 'sl';
    }
}
