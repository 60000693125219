
import { Vue, Component, Prop } from 'vue-property-decorator';
import PhoneNumberInput from '@/components/global/PhoneNumberInput.vue';

@Component({
    name: 'UsersTable',
    components: { PhoneNumberInput },
})
export default class UsersTable extends Vue {
    @Prop({ required: true }) public value!: any[];
    @Prop({ required: true }) public country!: Number;

    private selectedRowKeys: any = [];
    private newIndex: number = 1;

private get selectedCountryCode(){
        switch(this.country){
            case 23: return 'SI';
            case 1: return 'AT';
            default: return 'SI';
        }
    }
    private get columns() {
        return [
            { title: this.$t('Name'), dataIndex: 'name', key: 'name', scopedSlots: { customRender: 'name' }, width: '15%' },
            {
                title: this.$t('Surname'),
                dataIndex: 'surname',
                key: 'surname',
                scopedSlots: { customRender: 'surname' },
                width: '15%',
            },
            {
                title: this.$t('Phone number'),
                dataIndex: 'phone',
                key: 'phone',
                scopedSlots: { customRender: 'phone' },
                width: '20%',
            },
            { title: this.$t('E-mail'), dataIndex: 'email', key: 'email', scopedSlots: { customRender: 'email' }, width: '15%' },
            {
                title: this.$t('Sales representative (YES/NO)'),
                dataIndex: 'salesRepresentative',
                key: 'salesRepresentative',
                scopedSlots: { customRender: 'salesRepresentative' },
                width: '8.75%',
            },
            {
                title: this.$t('Contact person for Roltek (YES/NO)'),
                dataIndex: 'contactPerson',
                key: 'contactPerson',
                scopedSlots: { customRender: 'contactPerson' },
                width: '8.75%',
            },
            {
                title: this.$t('Administrator (YES/NO) - only one person'),
                dataIndex: 'admin',
                key: 'admin',
                scopedSlots: { customRender: 'admin' },
                width: '8.75%',
            },
            {
                title: this.$t('Sees purchase price (YES/NO)'),
                dataIndex: 'seesPrice',
                key: 'seesPrice',
                scopedSlots: { customRender: 'seesPrice' },
                width: '8.75%',
            },
        ];
    }

    private onSelectChange(selectedRowKeys: string[]) {
        this.selectedRowKeys = selectedRowKeys;
    }

    private addNewRow() {
        this.newIndex++;

        this.$emit('input', [
            ...this.value,
            {
                key: this.newIndex,
                name: '',
                surname: '',
                phone: '',
                email: '',
                salesRepresentative: true,
                contactPerson: true,
                admin: true,
                seesPrice: true,
            },
        ]);
    }

    private removeRows() {
        let currentRows = [...this.value];

        currentRows = currentRows.filter((row) => {
            const isSetToDelete = this.selectedRowKeys.findIndex((key: string) => key === row.key);

            if (isSetToDelete === -1) {
                return true;
            } else {
                return false;
            }
        });

        this.$emit('input', [...currentRows]);
        this.selectedRowKeys = [];
    }
}
