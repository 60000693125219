var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"company-information"},[_c('AForm',[_c('AFormItem',{staticClass:"company-information__form-field"},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('Name'))+" ")]),_c('AInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'title',
                    {
                        rules: [{ required: true, message: _vm.$t('Please input title!'), whitespace: true }],
                    },
                ]),expression:"[\n                    'title',\n                    {\n                        rules: [{ required: true, message: $t('Please input title!'), whitespace: true }],\n                    },\n                ]"}],model:{value:(_vm.value.title),callback:function ($$v) {_vm.$set(_vm.value, "title", $$v)},expression:"value.title"}})],1),_c('AFormItem',{staticClass:"company-information__form-field"},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('Tax number'))+" ")]),_c('AInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'taxNumber',
                    {
                        rules: [{ required: true, message: _vm.$t('Please input tax number!'), whitespace: true }],
                    },
                ]),expression:"[\n                    'taxNumber',\n                    {\n                        rules: [{ required: true, message: $t('Please input tax number!'), whitespace: true }],\n                    },\n                ]"}],attrs:{"type":"number"},model:{value:(_vm.value.taxNumber),callback:function ($$v) {_vm.$set(_vm.value, "taxNumber", $$v)},expression:"value.taxNumber"}})],1),_c('AFormItem',{staticClass:"company-information__form-field taxable-person"},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('Taxable person'))+" ")]),_c('a-switch',{model:{value:(_vm.value.taxablePerson),callback:function ($$v) {_vm.$set(_vm.value, "taxablePerson", $$v)},expression:"value.taxablePerson"}})],1),_c('AFormItem',{staticClass:"company-information__form-field"},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('Street and house number'))+" ")]),_c('AInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'streetNumber',
                    {
                        rules: [
                            { required: true, message: 'Please input street and house number!', whitespace: true },
                        ],
                    },
                ]),expression:"[\n                    'streetNumber',\n                    {\n                        rules: [\n                            { required: true, message: 'Please input street and house number!', whitespace: true },\n                        ],\n                    },\n                ]"}],model:{value:(_vm.value.streetNumber),callback:function ($$v) {_vm.$set(_vm.value, "streetNumber", $$v)},expression:"value.streetNumber"}})],1),_c('AFormItem',{staticClass:"company-information__form-field"},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('Postal code'))+" ")]),_c('AInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'postCode',
                    {
                        rules: [{ required: true, message: 'Please input post code!', whitespace: true }],
                    },
                ]),expression:"[\n                    'postCode',\n                    {\n                        rules: [{ required: true, message: 'Please input post code!', whitespace: true }],\n                    },\n                ]"}],model:{value:(_vm.value.postCode),callback:function ($$v) {_vm.$set(_vm.value, "postCode", $$v)},expression:"value.postCode"}})],1),_c('AFormItem',{staticClass:"company-information__form-field"},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('City'))+" ")]),_c('AInput',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'title',
                    {
                        rules: [
                            {
                                required: true,
                                message: 'Please input place and town information!',
                                whitespace: true,
                            },
                        ],
                    },
                ]),expression:"[\n                    'title',\n                    {\n                        rules: [\n                            {\n                                required: true,\n                                message: 'Please input place and town information!',\n                                whitespace: true,\n                            },\n                        ],\n                    },\n                ]"}],model:{value:(_vm.value.poste),callback:function ($$v) {_vm.$set(_vm.value, "poste", $$v)},expression:"value.poste"}})],1),_c('AFormItem',{staticClass:"company-information__form-field"},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t('Country'))+" ")]),(_vm.value.country)?_c('div',{staticClass:"flag-container",style:({ 'background-image': _vm.flagUrl }),attrs:{"data-test":"company-country-flag"}}):_vm._e(),_c('ASelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'country',
                    {
                        rules: [
                            {
                                required: true,
                                message: 'Please input country information!',
                                whitespace: true,
                            },
                        ],
                    },
                ]),expression:"[\n                    'country',\n                    {\n                        rules: [\n                            {\n                                required: true,\n                                message: 'Please input country information!',\n                                whitespace: true,\n                            },\n                        ],\n                    },\n                ]"}],attrs:{"placeholder":_vm.$t('Odaberi dr\u017eavu'),"filterOption":"","optionFilterProp":"children","show-search":""},model:{value:(_vm.value.country),callback:function ($$v) {_vm.$set(_vm.value, "country", $$v)},expression:"value.country"}},_vm._l((_vm.countries),function(country){return _c('ASelectOption',{key:country.id,attrs:{"data-test":country.name,"value":country.id}},[_vm._v(" "+_vm._s(country.name)+" ")])}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }